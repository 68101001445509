<template>
  <section>
    <!-- DASHBOARD BRAND-->
    <dashboard-Brand v-if="groupRol !== 'creator'" @open_side_summary="openSummaryProfile"></dashboard-Brand>
    <!-- DASHBOARD CREATOR-->
    <dashboard-Creator v-if="groupRol === 'creator'" class="mt-1"></dashboard-Creator>

    <sidebar-search-new
      :open_sidebar="open_sidebar" 
      :key="update_sidebar" 
      :data="data_sidebar"
      :network="select_network"
      class="open-sidebar-search"
      v-if="open_sidebar"
      @get_profile_url="getProfileUrl"
    >
    </sidebar-search-new>
  </section>
</template>

<script>
import DashboardBrand from '@/views/dashboard/analytics/DashboardBrand.vue'
import DashboardCreator from '@/views/dashboard/analytics/DashboardCreator.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import service_leads from '@/services/leads'
import service from '@/services/search'
import { toCurrency } from '@/libs/utils/formats'

export default {
  components: {
    DashboardBrand,
    DashboardCreator,
    SidebarSearchNew: () => import('@/views/buscador/SidebarSearchNew.vue'),
  },
  data() {
    return {
      open_sidebar: false,
      update_sidebar: 0,
      data_sidebar: {},
      select_network: 'instagram'
    }
  },
  beforeCreate () {
    const user = JSON.parse(localStorage.userData)
    this.meta_info = user.meta_info 
    const route = getHomeRouteForLoggedInUser(user)
    const redirect = route.name === 'terms-conditions' || route.name === 'account-company' || route.name === 'auth-login' || route.name === 'auth-roll'

    if (redirect) this.$router.push(route)
    
    this.groupRol = user.main_group.name;

    if (this.groupRol === 'realty') this.$router.push({name: 'campaigns'})

    service_leads.getDetail('grandmaster').then(response => {
      this.participation_lead = response.participation;
      this.total_balance = response.participation ? toCurrency(response.participation.available_amount) : toCurrency(0);
    });
  },
  methods: {
    openSummaryProfile(item) {

      service.getCategories(item.type).then((response) => {
        

        this.data_sidebar = {
          'username': {
            'name': {
              username: item.type !== 'youtube' ? item.username : item.channel_id,
              ava: item.avatar_url,
              avatar: item.avatar_url,
              fullname: item.title
            }
          },
          followers: item.subscribers_count,
          categories: {
            all_categories: item.type !== 'youtube' ? response.labels : response.thematics
          }
        }
        this.select_network = item.type;

        this.open_sidebar = true;
        this.update_sidebar += 1;
      });
 
    },
    getProfileUrl() {
      console.log('Evento');
    }
  },
};
</script>
<style scoped>
.subtitle-alert-creator {
  color: white;
}
</style>
<style>
.open-sidebar-search > .b-sidebar-right {
  animation: sidebar 500ms !important;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
</style>