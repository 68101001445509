import { render, staticRenderFns } from "./DashboardBrand.vue?vue&type=template&id=e78fdfc2&scoped=true&"
import script from "./DashboardBrand.vue?vue&type=script&lang=js&"
export * from "./DashboardBrand.vue?vue&type=script&lang=js&"
import style0 from "./DashboardBrand.vue?vue&type=style&index=0&lang=css&"
import style1 from "./DashboardBrand.vue?vue&type=style&index=1&id=e78fdfc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e78fdfc2",
  null
  
)

export default component.exports